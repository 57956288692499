import {Component} from "react";
import {connect} from "react-redux";
import {mapWarehouseDispatchToProps, mapWarehouseStateToProps} from "../../../redux/Actions/WarehouseAction";
import {withTranslation} from "react-i18next";

class WarehouseInventoryInfo extends Component {
    render() {
        let {editable, warehouse_id_mapping, t, onEdit, onDelete, inventory} = this.props;
        console.log(warehouse_id_mapping)
        return (
            <div className={'d-flex w-100 address-add-row px-3 mt-0'} style={{borderColor: '#dbdbdb'}}>
                <div className={'flex-grow-1'} style={{ color: 'black', fontWeight: '500'}}>{warehouse_id_mapping[inventory.warehouse].name}</div>
                <div className={'mx-1'}>
                    <div>{t('in_stock')}: {inventory.in_stock}</div>
                    <div>{t('intransit_new')}: {inventory.in_transit}</div>
                </div>

                {
                    editable === true &&
                    <div className={'mx-1'}>
                        <small className={'mx-1 text-capitalize'} onClick={(e) => onEdit(e)}
                               style={{cursor: 'pointer', verticalAlign: 'top', color: 'black', textDecoration: 'underline'}}>EDITER</small>
                        <small className={'text-capitalize'} onClick={(e) => onDelete(e)}
                               style={{cursor: 'pointer', verticalAlign: 'top', color: 'black', textDecoration: 'underline'}}>ÉFFACER</small>
                    </div>
                }
            </div>
        )
    }

}

export default withTranslation('common')(connect(mapWarehouseStateToProps, mapWarehouseDispatchToProps)(WarehouseInventoryInfo))