import React, {Component} from 'react';
import {isAccessible} from "../../../../utils";
import WarehouseInventoryInfo from "../WarehouseInventoryInfo";

class InventoryInformation extends Component {
    calculateTotals = () => {
        let total_in_stock = 0;
        let total_in_transit = 0;
        for (let item of this.props.warehouseVariantInventory) {
            total_in_stock += item.in_stock;
            total_in_transit += item.in_transit;
        }
        return {
            total_in_stock,
            total_in_transit,
        }
    }
    render() {
        const {t, warehouseVariantInventory, variant} = this.props;
        let [total_in_stock,total_in_transit] = [0,0];

        if (warehouseVariantInventory !== undefined) {
            const {total_in_stock:_ts, total_in_transit: _tt} = this.calculateTotals();
            total_in_stock = _ts;
            total_in_transit = _tt;
        }
        else {
            const {in_stock:_ts, in_transit: _tt} = variant;
            total_in_stock = _ts;
            total_in_transit = _tt;
        }

        return (
            <div className="col-12 px-0">
                <div className="row card-details-general-row py-2">
                    <div className="col-lg-6 col-sm-12 col-12 col-md-6">
                        <ul className="list-inline mb-0 w-100">
                            <li className="list-inline-item width-100-tab w-50 m-0">{t('in_stock')} :</li>
                            <li className="list-inline-item width-100-tab w-50 m-0">
                                {isAccessible(['admin'], true) ?
                                    <i style={{color: `${total_in_stock > 0 ? 'green' : 'red'}`}}
                                       className="fa fa-circle"/>
                                    : <span className="text-success">{total_in_stock} articles </span>}

                            </li>
                        </ul>
                    </div>
                    <div className="col-lg-6 col-sm-12 col-12 col-md-6">
                        <ul className="list-inline mb-0 w-100">
                            <li className="list-inline-item width-100-tab w-50 m-0">{t('transit')} :</li>
                            <li className="list-inline-item width-100-tab w-50 m-0">
                                {isAccessible(['admin'], true) ?
                                    <i style={{color: `${total_in_transit > 0 ? 'green' : 'red'}`}}
                                       className="fa fa-circle"/>
                                    : <span>{total_in_transit} articles</span>}
                            </li>
                        </ul>
                    </div>
                </div>
                {
                    isAccessible(['admin']) && warehouseVariantInventory && warehouseVariantInventory.map((inventory, key) => {
                        return (
                            <WarehouseInventoryInfo
                                inventory={inventory}
                                editable={false}
                                key={key}/>
                        )
                    })
                }
            </div>
        );
    }
}

export default InventoryInformation;