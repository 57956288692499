import React, {Component} from 'react';
import {Button, Spin} from "antd";
import {
    locationGroupItemGet,
    locationItemRemove,
    locationItemUpdate
} from "../../../../controller/API/salesOperationAPI";
import {AddGroupCommentPopup} from "../modals/AddGroupCommentPopup";
import {withTranslation} from "react-i18next";
import SingleItemListCard from "../SingleItemListCard";
import SingleItembygroup from '../SingleItembygroup';
import {connect} from "react-redux";
import {mapWarehouseDispatchToProps, mapWarehouseStateToProps} from "../../../../redux/Actions/WarehouseAction";
import SingleItemListCardDiscount from "../disocuntItem/SingleItemListCardDiscount";
import {isAccessible} from "../../../../utils";

const moment = require('moment');


class GroupItemList extends Component {
    state = {
        itemListShow: false,
        items: [],
        params: {location_id: this.props.location.id},
        loading: true,
        commentPopup: false,
        commentData: null
    };

    itemListVisible = (visible) => {
        this.setState({
            itemListShow: visible,
        });
    };
    fetch = (params = {}) => {
        locationGroupItemGet(params)
            .then(response => {
                this.setState({items: response, loading: false})
            })
    };
    onChangeOrderType = (id, e) => {
        const data = {order_type: e.target.value};
        this.updateItem(id, data)
    };

    deleteItem = (id) => {
        locationItemRemove(id)
            .then(() => this.fetch(this.state.params))
    };

    visibleCommentPopup = (visible, data = null) => {
        this.setState({commentPopup: visible, commentData: data})
    };

    componentDidMount() {
        const {params} = this.state;
        this.fetch(params);
    }

    updateItem(id, data) {
        locationItemUpdate(id, data)
            .then(() =>
                this.fetch({location_id: this.props.location.id}))
    }

    getItemsList(items) {
        const {params} = this.state;

        const warehouseMapping = {}
        for (let item of items) {
            if (!(item.warehouse_item.warehouse in warehouseMapping)) {
                warehouseMapping[item.warehouse_item.warehouse] = []
            }
            warehouseMapping[item.warehouse_item.warehouse].push(item)
        }
        let nodes = []
        for (const warehouse in warehouseMapping) {
            if (isAccessible(['admin'])) {
                nodes.push(
                    <p className={`mt-1 text-left font-weight-bold`} style={{color: '#448de5'}}
                       key={`warehouse_${warehouse}`}>
                        {this.props.warehouse_id_mapping[warehouse].name} <br/>
                        <span
                            className={'font-weight-normal'}>{this.props.warehouse_id_mapping[warehouse].address}</span><br/>
                        <span
                            className={'font-weight-normal'}>{this.props.warehouse_id_mapping[warehouse].business_hours}</span><br/>
                        <span className={'font-weight-normal'}>{this.props.warehouse_id_mapping[warehouse].phone}</span>
                    </p>
                )
            }

            warehouseMapping[warehouse].map((data, index) => nodes.push(
                <SingleItembygroup key={`item_card_${index}`} data={data} update={(id, data) => this.updateItem(id, data)}
                                   fetch={() => this.fetch(params)}
                                   delivery_setting={true}
                                   workorder={this.props.workorder}
                                   remove={(id) => this.deleteItem(id)}/>
            ))

        }
        return nodes
    }

    render() {
        const {items, loading, commentData, commentPopup, params} = this.state;
        const {t} = this.props;
        if (loading) {
            return <div className={'mt-5 p-0 text-center'}><Spin/></div>
        }
        return (
            <React.Fragment>
                {items.map((data, index) =>
                    <React.Fragment key={`group_item_${index}`}>
                        <div className="group-date pb-0">
                            <h5><span
                                className="text-uppercase">{data.name}</span> : <span>{moment(data.delivery_date).format('DD/MM/YYYY')} </span><span> {data.comment}</span>
                            </h5>
                            <Button className="add-data-btn ml-2" onClick={() => this.visibleCommentPopup(true, data)}>
                                {data.comment ? t('edit') : t('add_comment')}</Button>
                        </div>
                        {this.getItemsList(data.items)}
                    </React.Fragment>
                )}
                {commentPopup &&
                <AddGroupCommentPopup visible={commentPopup} close={() => this.visibleCommentPopup(false)}
                                      data={commentData} fetch={() => this.fetch(params)}/>}
            </React.Fragment>
        );
    }
}

export default (withTranslation('common')(connect(mapWarehouseStateToProps, mapWarehouseDispatchToProps)(GroupItemList)));
