import React, {Component} from 'react';
import CustomerPurchase from "./CustomerPurchase";
import {Button, Form, InputNumber,Collapse} from "antd";
import {withTranslation} from "react-i18next";
import {GroupPopoverContent} from "./added-item-group/GroupPopoverContent";
import { quantity_update } from '../../../controller/API/salesOperationAPI';
import DiscountCard from './disocuntItem/DiscountCard';
import {isAccessible, Role} from '../../../utils';
const { Panel } = Collapse;
class SingleItembygroup extends Component {

    onInputNumberChange = (value) => {
        if (value) {
            const {data} = this.props
            this.props.update(data.id,{'quantity':value})
        }
    }

    render() {
        const {t, data, delivery_setting} = this.props;
        const {getFieldDecorator} = this.props.form;
        return (<div className="row card-main-common">
            <div className="col-12">
                <div className="row added-cart-item m-0">
                    <div className="col-sm-3 col-12 added-cart-img">
                        <img className="img-fluid"
                             src={data.warehouse_item.variant.variant_images.length > 0 ? data.warehouse_item.variant.variant_images[0].image : ""}
                             alt="item"/>
                    </div>
                    <div className="col-sm-5 col-12 added-cart-price">
                        <h5>{data.warehouse_item.variant.product.name} <span>{data.warehouse_item.variant.sku}</span></h5>
                        <p className="added-color">
                            <small>{data.warehouse_item.variant.product.category.name}</small>
                            <i style={{backgroundColor: `${data.warehouse_item.variant.colour_code}`}}
                               className="fa fa-circle"/>{data.warehouse_item.variant.colour}</p>
                        <ul className="list-inline mb-0 price-qty-ul">
                            <Role allow={['admin']}>
                                <li>€{parseFloat(data.single_item_price).toFixed(2)}</li>
                            </Role>
                            <li>QTY: {data.quantity}</li>
                        </ul>
                    </div>
                    <CustomerPurchase data={data} fetch={() => this.props.fetch()}/>
                    <div className="group-action-div">
                        {delivery_setting && <GroupPopoverContent
                            workorder={this.props.workorder}
                            title={data.warehouse_item.variant.product.name}
                            data={data}
                            fetch={() => this.props.fetch()}
                        />}
                        <Button className="added-btn"
                                onClick={() => this.props.remove(data.id)}>{t('delete_icon')}</Button>
                    </div>
                </div>
                {data.workorder_component.length ?
                            <div style={{backgroundColor:'#ebecef',paddingBottom:'5px'}} className="w-100">
                            {data.workorder_component?.map((item) => {                            
                                return <DiscountCard item={item} fetch={this.props.fetch} />
                            })}
                            </div>
                    : ""}
            </div>
            <div className="col-12">
                <div className="row mx-0 card-details-footer">
                    <div className="col-12 sm-12 col-md-6">
                        {isAccessible(['admin']) &&
                        <h6 className="mb-0">Prix article + Prix composant (si présent)</h6>
    }
                    </div>
                    <div className="col-12 sm-12 col-md-3 pl-0">
                        <ul className="list-inline mb-0 d-flex align-items-center">
                            <li className="list-inline-item">QTY:</li>
                            <li className="list-inline-item">
                                {data.quantity}
                            </li>
                        </ul>
                    </div>
                    {isAccessible(['admin']) && 
                    <div className="col-12 sm-12 col-md-3 border-left-1">
                        <ul className="list-inline mb-0 text-right">
                            <li className="list-inline-item pr-4">Prix total:</li>
                            <li className="list-inline-item">{parseFloat(data.total_price).toFixed(2)}</li>
                        </ul>
                    </div>
    }
                </div>
            </div>
        </div>);
    }
}

export default Form.create()(withTranslation('common')(SingleItembygroup));
