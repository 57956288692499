import React, {Component} from "react";
import {Button, Form, Icon, Input, InputNumber} from "antd";
import ItemDetailsModal from "../../../modal/work-order/ItemDetailsModal";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {mapWarehouseDispatchToProps, mapWarehouseStateToProps} from "../../../../redux/Actions/WarehouseAction";
import { isAccessible } from "../../../../utils";

const moment = require("moment");

class SingleItemCardForm extends Component {

    state = {
        itemDetailsShow: false,
        selected: 0,
        price: null,
        quantity: this.props.product.variant[0].units_per_set,
        buttonLoading: false
    };

    handleSubmit = e => {
        e.preventDefault();
        this.setState({buttonLoading: true});
        const {selected, price} = this.state;
        const variants = this.getFilteredVariants(this.props.product, this.props.warehouse);
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.props.submit(values, this.props.variant === true ? variants[selected].id : selected, price.toFixed(2));
                this.setState({buttonLoading: false});
            }
        });
    };

    setPrice = (quantity) => {
        const {selected} = this.state;
        this.setState({price: this.getPrice(selected, quantity), quantity});
    };

    getPrice = (selected, quantity = null) => {
        const {product} = this.props;
        let variants = this.getFilteredVariants(product, this.props.warehouse);

        if (variants[selected] === undefined) {
            return 0;
        }

        if (!quantity) {
            quantity = variants[selected].units_per_set;
        }
        return quantity * variants[selected].price;
    };

    componentDidMount() {
        this.setState({price: this.getPrice(this.state.selected)});
    }

    itemDetailsVisible = (visible) => {
        this.setState({
            itemDetailsShow: visible
        });
    };
    changeSelected = (selected) => {
        let variants = this.getFilteredVariants(this.props.product, this.props.warehouse)
        this.setState({
            selected, quantity: variants[selected].units_per_set,
            price: this.getPrice(selected)
        });
    };

    getFilteredVariants = (product, warehouse) => {
        let variants = product.variant;
        if (warehouse !== undefined && warehouse !== null) {
            variants = variants.filter(variant => variant.warehouse_inventory.includes(warehouse))
        }
        return variants
    }

    render() {
        const {product, t, container, warehouse} = this.props;
        const {getFieldDecorator} = this.props.form;
        const {selected, itemDetailsShow, price, buttonLoading} = this.state;
        let variants = this.getFilteredVariants(product, warehouse);
        return (
            <>
                {
                    variants.length === 0 ? <div></div> :
                    <>
                        <div className="col-sm-12 col-lg-6 col-md-12 col-12 pr-2 padding-responsive-right">
                            <div className="row mx-0 item_single-card">
                                <div className="item-img-div flex-align-center-center">
                                    <Button onClick={() => this.itemDetailsVisible(true)}
                                            className="bg-transparent shadow-none p-0 border-0 h-auto">
                                        <img
                                            src={variants[selected].variant_images.length > 0 ? variants[selected].variant_images[0].image : ""}
                                            className="img-fluid" alt="chair-img"/>
                                    </Button>
                                </div>
                                <div className="item-data-div position-relative">
                                    <h5 className="font-weight-bold">{product.name}</h5>
                                    <p className="font-weight-normal">{t("product_ref")} : {variants[selected].sku}</p>
                                    {isAccessible(['admin']) &&
                                    <ul className="list-inline mb-0 w-100">
                                        <li className="list-inline-item m-0">{t("selling_price")} :</li>
                                        <li
                                            className="list-inline-item m-0">€{parseFloat(variants[selected].price).toFixed(2)}</li>
                                    </ul>
    }
                                    <ul className="list-inline mb-0 w-100">
                                        <li className="list-inline-item ">{t("category")} :</li>
                                        <li className="list-inline-item  dark-gray">{product.category.name}</li>
                                    </ul>
                                    {isAccessible(['admin']) &&
                                    <ul className="list-inline mb-0 w-100">
                                        {
                                            variants && variants[selected]?.warehouse_inventory_object.map(
                                                (warehouse_inventory, index) => (
                                                    <React.Fragment key={`wv_${variants[selected].id}_${warehouse_inventory.id}`}>
                                                        <li className="list-inline-item ">{this.props.warehouse_id_mapping[warehouse_inventory.warehouse].name} :</li>
                                                        <li className={`list-inline-item ${warehouse_inventory.in_stock > 0 ? 'text-success' : 'text-danger'}`}>
                                                            {warehouse_inventory.in_stock}
                                                        </li>
                                                    </React.Fragment>
                                                )
                                            )
                                        }
                                    </ul>
    }
                                    <ul className="list-inline mb-0 w-100">
                                        <li className="list-inline-item ">{t("conditioning")} :</li>
                                        <li className="list-inline-item  dark-gray">{variants[selected].units_per_set}</li>
                                    </ul>
                                    {container ? "" : <ul className="list-inline mb-0 w-100">
                                        <li className="list-inline-item m-0">{t("diliver_soon")} :</li>
                                        <li className="list-inline-item m-0 dark-gray">{variants[selected].in_stock ?
                                            moment().add(21, "days").format("DD/MM/YYYY") :
                                            moment().add(60, "days").format("DD/MM/YYYY")}</li>
                                    </ul>}
                                    <ul className="list-inline mb-0 w-100">
                                        <li className="list-inline-item ">{t("color")} :</li>
                                        <li className="list-inline-item ">
                                            {variants
                                                .map((variant, index) =>
                                                    <span key={`color_code_${index}`} style={{backgroundColor: variant.colour_code}}
                                                          onClick={() => this.changeSelected(index)}
                                                          className={`color-card d-inline-block mr-1 ${selected === index ? "active" : null}`}/>
                                                )}
                                        </li>
                                    </ul>
                                    <ul className="list-inline mb-0 w-100">
                                        <li className="list-inline-item ">{t("quantity")} :</li>
                                        <li className="list-inline-item ">
                                            <div className="d-flex align-items-center item-qty-tag-div position-relative">
                                                <Form.Item className="mb-0">
                                                    {getFieldDecorator("quantity", {
                                                        initialValue: this.state.quantity
                                                    })(
                                                        <InputNumber className="mb-0" onChange={this.setPrice}
                                                                     step={variants[selected].units_per_set}
                                                                     min={variants[selected].units_per_set}/>
                                                    )}
                                                </Form.Item>
                                                <h6 className="mb-0">{this.state.quantity / variants[selected].units_per_set} sets</h6>
                                            </div>
                                        </li>
                                    </ul>
                                    {
                                        container && <ul className="list-inline mb-0 w-100 pi-number-ul">
                                            <li className="list-inline-item ">Numéro de PI :</li>
                                            <li className="list-inline-item "><Form.Item className="mb-0">
                                                {getFieldDecorator("pi_number", {})(
                                                    <Input className="mb-0"/>
                                                )}
                                            </Form.Item></li>
                                        </ul>
                                    }

                                    <div
                                        className="card-footer-div flex-align-center justify-content-end position-absolute">
                                        <div className="mr-3 card-text-footer">
                                            {isAccessible(['admin']) &&
                                            <h6 className="mb-0 font-weight-bold active">
                                                <p>€{price ? price.toFixed(2) : 0}</p>
                                            </h6>
    }
                                        </div>
                                        <Button loading={buttonLoading} type="primary" onClick={this.handleSubmit}>
                                            <Icon type="plus"/>
                                            {t("add")}
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {itemDetailsShow &&
                            <ItemDetailsModal quantity={this.state.quantity} onChange={this.setPrice}
                                              handleSubmit={this.handleSubmit}
                                              location={this.props.location}
                                              container={this.props.container}
                                              product={product} visible={itemDetailsShow}
                                              onClose={() => this.itemDetailsVisible(false)}/>}
                    </>
                }

            </>
        );
    }
}

export const SingleItemCard = Form.create()(withTranslation("common")(connect(mapWarehouseStateToProps, mapWarehouseDispatchToProps)(SingleItemCardForm)));
