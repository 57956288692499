import React, { Component } from "react";
import { Button, Drawer, Input, message, Select, Spin } from "antd";
import { Image as Images } from "../../../Images";
import {
  ComponentGet,
  itemCategoryGet,
  productGet,
  variantGet,
  variantGetOne,
} from "../../../../controller/API/itemApi";
import { SingleItemCard } from "./singleItemCard";
import { withTranslation } from "react-i18next";
import {
  distributorWorkOrderDrawer,
  distributorWorkorderItemAdd,
  workorder_component,
} from "../../../../controller/API/salesOperationAPI";
import ComponentItemCard from "../../../work-order/distributors/ComponentItemCard";

const { Search } = Input;

const { Option } = Select;

class AddComponentListDrawerStep3 extends Component {
  state = {
    items: [],
    category: [],
    params: { workorder_item: null },
    fetched: false,
    loading: false,
    totalItems: this.props.totalItems,
    page: 1,
    is_load_more: true,
    updateItems: false,
    data: [],
    cardid: [],
  };

  componentDidMount() {
    this.setState({data:this.props.ComponentData})
  }

  handleCard = (componentData) => {
    let warehouseComponentInventoryID = componentData?.warehouse_inventory_object.find(warehouse_inventory => warehouse_inventory.warehouse === this.props.warehouseID)?.id;
    const payload = {
      quantity: this.props.data?.quantity,
      workorder_item: this.props.data?.id,
      warehouse_component: warehouseComponentInventoryID,
    };
    distributorWorkOrderDrawer(payload).then((value) => {
      message.success("Article Added Successfully");
        this.setState({ cardid: [...this.state.cardid, componentData.id] });
    }).catch((error)=>{
        message.info(error.response.data.message)
    })
  };

  render() {
    const { current_location, t } = this.props;
    const {
      category,
      buttonLoading,
      totalItems,
      loadMoreLoading,
      is_load_more,
      updateItems,
      data,
    } = this.state;
    const items = this.state.items.filter((o) => o.variant.length > 0);
    if (this.state.loading) {
      return (
        <div className={"mt-5 p-0 text-center"}>
          <Spin />
        </div>
      );
    }
    return (
      <Drawer
        title={t("add_article")}
        placement="right"
        closable={false}
        onClose={this.props.onClose}
        visible={this.props.visible}
        width="40%"
      >
        <div className="row mx-0">
          <Button
            onClick={this.props.onClose}
            className="close-header-icon w-auto flex-align-center-center px-3 position-absolute p-0 border-0"
          >
            <img
              src={Images.close_icon}
              alt="close icon"
              className="img-fluid"
            />
            <div>{t("close_btntext")}</div>
          </Button>
          <div className="col-12 clearfix list-main-drawer">
            <div className="row mx-0 list-item-card-row">
              {data?.map((val) => {
                return (
                  <>
                    <div className="col-12">
                      <div
                        className="row added-cart-item mx-0 coupon-row p-0"
                        style={{
                          border: "1px solid #e0e0e0",
                          backgroundColor: "#FBF7FD",
                        }}
                      >
                        <div className="col-sm-3 col-12 added-cart-img">
                          <img
                            className="img-fluid"
                            src={val?.images?.length && val.images[0].image}
                            alt="item"
                          />
                        </div>
                        <div className="col-12 col-sm-9 py-3">
                          <div className="row">
                            <div
                              style={{ borderRight: "1px solid #DCC4E5" }}
                              className="col-sm-6 pt-0 pb-0 col-12 added-cart-price px-3"
                            >
                              <h5>
                               {val.sku}
                              </h5>
                              <ul className="added-pricing justify-content-start list-inline">
                                <li className="list-inline-item">
                                  <small style={{ color: "#646464" }}>
                                    {t(val.piece_type)}
                                  </small>
                                </li>
                                <li className="list-inline-item">
                                  <span
                                    className="d-inline-block"
                                    style={{
                                      width: "27px",
                                      height: "11px",
                                      backgroundColor:
                                        val.colour_code,
                                    }}
                                  ></span>
                                </li>
                                <li className="list-inline-item">
                                  <small style={{ color: "#646464" }}>
                                    {val.colour}
                                  </small>
                                </li>
                              </ul>
                            </div>
                            <div className="col-sm-6 col-12 added-cart-list px-3">
                              <div className="row mx-0 flex-align-center">
                                {this.state?.cardid?.find(
                                  (value) => value === val.id
                                ) ? (
                                  <Button
                                    type="primary"
                                    disabled={true}
                                    style={{ backgroundColor: "#3E65B0" }}
                                    className="plus-btn text-uppercase main-btn-tag flex-align-center text-white font-weight-bold pl-2 pr-3"
                                  >
                                    ADDED
                                  </Button>
                                ) : (
                                  <Button
                                    onClick={() => {
                                      this.handleCard(val);
                                    }}
                                    type="primary"
                                    className="plus-btn text-uppercase main-btn-tag flex-align-center text-white font-weight-bold pl-2 pr-3"
                                  >
                                    <img src={Images.plus_icon_white} />
                                    {t("add")}
                                  </Button>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        </div>
      </Drawer>
    );
  }
}

export default withTranslation("common")(AddComponentListDrawerStep3);
